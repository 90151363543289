onDomReady(function () {
   if (App.FrameModules) {
      FrameModules.execute();
   }
});

// eslint-disable-next-line no-var
export var FrameModules = (window.FrameModules = {
   _dynamicModules: {
      CommentsFrameModule: async () => {
         const { initCommentsFrameModule } = await import('Guide/FrameModules/comments');
         initCommentsFrameModule();
      },
      MessageUserFrameModule: async () => {
         const { MessageUser } = await import('Guide/FrameModules/message_user.js');
         MessageUser.initialize();
      },
   },

   _modules: {},

   add: function (name, fn) {
      // Push the framemodule javascript onto the queue to be executed only
      // when the FrameModule is included on the page.
      this._modules[name] = fn;
   },

   execute: function () {
      let self = this;
      App.FrameModules.each(function (name) {
         if (name in self._dynamicModules) {
            self._dynamicModules[name]();
         } else if (name in self._modules) {
            self._modules[name]();
         }
      });
   },
});
